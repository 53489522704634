// @flow
import { decorate, observable, action } from 'mobx';
import BrightApi from '../../api';
import type { SearchResult } from 'bright';

class SearchStore {
  results: Array<SearchResult> = [];
  detailResults: Array<SearchResult> = [];
  totalResultCount: Number = 0;
  searchTerm: String = '';

  async search(term, limit = 5000) {
    this.searchTerm = term;
    const response = await BrightApi.search(term, limit);

    this.totalResultCount = response.headers['x-total-result-count'];
    this.results = response.data;

    return this.results;
  }

  clearResults = () => {
    this.results = [];
  }
}

decorate(SearchStore, {
  results: observable,
  search: action
});

export default new SearchStore();
