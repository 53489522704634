// @flow
import { decorate, observable, action } from 'mobx';
import Api from '../../api';

import notificationsStore from '../notifications/NotificationsStore';

class TeamsStore {
  constructor() {
    this.totalTeamsCount = 0;
    this.teamsList = [];
    this.tierOneTeams = [];
    this.tierTwoTeams = [];
    this.tierThreeTeams = [];
    this.isTeamsListOverviewLoading = false;
    this.isTeamsListLoading = false;
  }

  // will be removed
  async getAllTeamsOverviewData(queryParams) {
    this.isTeamsListOverviewLoading = true;

    const { data } = await Api.getAllTeamsOverviewData(queryParams);
    this.isTeamsListOverviewLoading = false;

    if (data) {
      this.totalTeamsCount = data.totalTeamsCount;
    }
  }

  async getTierOneTeams() {
    this.isTeamsListLoading = true;

    const { data: { teams } } = await Api.getTierOneTeams();
    this.isTeamsListLoading = false;

    if (teams) {
      this.tierOneTeams = teams;
      this.teamsList = teams;
    }
  }

  // used when user clicks on '+' button
  async getAllTeamsListing(queryParams) {
    this.isTeamsListLoading = true;

    const { data: { teams, tierId, parentId } } = await Api.getAllTeamsListing(queryParams);
    this.isTeamsListLoading = false;

    if (teams) {
      if (tierId === 1) {
        this.tierOneTeams = teams;
        this.teamsList = teams;
      } else if (tierId === 2) {
        const oldTeams = this.teamsList;
        const newTeams = oldTeams.map((team) => {
          if (team.id === Number(parentId)) {
            team = { ...team, subRows: teams, expanded: true };
          }
          return team;
        });
        this.teamsList = newTeams;
      } else if (tierId === 3) {
        const oldTeams = this.teamsList;
        const newTeams = oldTeams.map((team) => {
          if (team.subRows) {
            team.subRows = team.subRows.map((te) => {
              if (te.id === Number(parentId)) {
                te = { ...te, subRows: teams, expanded: true };
              }
              return te;
            });
          }
          return { ...team };
        });
        this.teamsList = newTeams;
      } else if (tierId === 4) {
        const oldTeams = this.teamsList;
        const newTeams = oldTeams.map((team) => {
          if (team.subRows) {
            team.subRows = team.subRows.map((te) => {
              if (te.subRows) {
                te.subRows = te.subRows.map((t) => {
                  if (t.id === Number(parentId)) {
                    t = { ...t, subRows: teams, expanded: true };
                  }
                  return t;
                });
              }
              return te;
            });
          }
          return { ...team };
        });
        this.teamsList = newTeams;
      }
    }
  }

  updateTeamName({
    tierId, id, name, parentIds,
  }) {
    if (tierId === 1) {
      this.tierOneTeams = this.tierOneTeams.map((t) => (t.id === id ? { ...t, name } : t));
      this.teamsList = this.teamsList.map((t) => (t.id === id ? { ...t, name } : t));
    } else if (tierId === 2) {
      this.tierTwoTeams = this.tierTwoTeams.map((t) => (t.id === id ? { ...t, name } : t));
      const selectedTeam = this.teamsList.find((team) => team.id === parentIds[0]);
      selectedTeam.subRows = selectedTeam.subRows.map((t) => (t.id === id ? { ...t, name } : t));
    } else if (tierId === 3) {
      this.tierThreeTeams = this.tierThreeTeams.map((t) => (t.id === id ? { ...t, name } : t));
      this.tierTwoTeams = this.tierTwoTeams.map((t) => (t.id === id ? { ...t, name } : t));
      const selectedTierOneTeam = this.teamsList.find((team) => team.id === parentIds[0]);
      const selectedTierTwoTeam = selectedTierOneTeam.subRows.find((team) => team.id === parentIds[1]);
      selectedTierTwoTeam.subRows = selectedTierTwoTeam.subRows.map((t) => (t.id === id ? { ...t, name } : t));
    } else if (tierId === 4) {
      this.tierThreeTeams = this.tierThreeTeams.map((t) => (t.id === id ? { ...t, name } : t));
      this.tierTwoTeams = this.tierTwoTeams.map((t) => (t.id === id ? { ...t, name } : t));
      const selectedTierOneTeam = this.teamsList.find((team) => team.id === parentIds[0]);
      const selectedTierTwoTeam = selectedTierOneTeam.subRows.find((team) => team.id === parentIds[1]);
      const selectedTierThreeTeam = selectedTierTwoTeam.subRows.find((team) => team.id === parentIds[2]);
      selectedTierThreeTeam.subRows = selectedTierThreeTeam.subRows.map((t) => (t.id === id ? { ...t, name } : t));
    }
    notificationsStore.createNotification('Team updated successfully');
  }

  // used when user selects any filters
  async getAllFilteredTeamsListing(queryParams) {
    this.isTeamsListLoading = true;

    const { data: { teams } } = await Api.getAllTeamsListing(queryParams);
    if (queryParams.tierId === 1) {
      this.tierTwoTeams = [...this.tierTwoTeams, ...teams];
    } else if (queryParams.tierId === 2) {
      this.tierThreeTeams = [...this.tierThreeTeams, ...teams];
    }
    this.isTeamsListLoading = false;
  }

  // used when user filter by search box
  async fetchFilteredTeamsBySearchTerm({ searchTerm }) {
    this.isTeamsListLoading = true;
    let teams = [];
    const { data } = await Api.fetchFilteredTeamsBySearchTerm({ searchTerm });
    if (data.teams) {
      teams = data.teams;
    }
    this.isTeamsListLoading = false;
    this.teamsList = teams;
  }

  // will fetch filtered teams
  async fetchFilteredTeams({
    tierOneIds, tierTwoIds, tierThreeIds, tierFourTeamInput, selectedTierOneTeam, selectedTierTwoTeam, selectedTierThreeTeam,
  }) {
    this.isTeamsListLoading = true;
    const teams = [];
    if (tierFourTeamInput) {
      const { data } = await Api.fetchFilteredTeams({
        tierOneIds, tierTwoIds, tierThreeIds, tierFourTeamInput,
      });
      if (data.teams && data.teams.length !== 0) {
        let includedTierThreeParentTeams = data.teams.map((team) => team.parent_id);
        includedTierThreeParentTeams = [...new Set(includedTierThreeParentTeams)];
        const tempThirdTier = [];
        includedTierThreeParentTeams.forEach((id) => {
          const r = data.teams.filter((team) => team.parent_id === id);
          const teamData = selectedTierThreeTeam.find((team) => team.id === id);
          tempThirdTier.push({ ...teamData, expanded: true, subRows: r });
        });

        let includedTierTwoParentTeams = selectedTierThreeTeam.filter((team) => includedTierThreeParentTeams.includes(team.id)).map((team) => team.parent_id);
        includedTierTwoParentTeams = [...new Set(includedTierTwoParentTeams)];
        const tempTwoTier = [];
        includedTierTwoParentTeams.forEach((id) => {
          const r = tempThirdTier.filter((team) => team.parent_id === id);
          const teamData = selectedTierTwoTeam.find((team) => team.id === id);
          tempTwoTier.push({ ...teamData, expanded: true, subRows: r });
        });

        let includedTierOneParentTeams = selectedTierTwoTeam.filter((team) => includedTierTwoParentTeams.includes(team.id)).map((team) => team.parent_id);
        includedTierOneParentTeams = [...new Set(includedTierOneParentTeams)];
        includedTierOneParentTeams.forEach((id) => {
          const r = tempTwoTier.filter((team) => team.parent_id === id);
          const teamData = selectedTierOneTeam.find((team) => team.id === id);
          teams.push({ ...teamData, expanded: true, subRows: r });
        });
      }
    } else {
      let includedTierThreeParentTeams = selectedTierThreeTeam.map((team) => team.id);
      includedTierThreeParentTeams = [...new Set(includedTierThreeParentTeams)];
      const tempThirdTier = [];
      includedTierThreeParentTeams.forEach((id) => {
        const r = selectedTierThreeTeam.filter((team) => team.parent_id === id);
        const teamData = selectedTierThreeTeam.find((team) => team.id === id);
        tempThirdTier.push({ ...teamData, expanded: false, subRows: r });
      });

      let includedTierTwoParentTeams = selectedTierThreeTeam.filter((team) => includedTierThreeParentTeams.includes(team.id)).map((team) => team.parent_id);
      includedTierTwoParentTeams = [...new Set(includedTierTwoParentTeams)];
      const tempTwoTier = [];
      includedTierTwoParentTeams.forEach((id) => {
        const r = tempThirdTier.filter((team) => team.parent_id === id);
        const teamData = selectedTierTwoTeam.find((team) => team.id === id);
        tempTwoTier.push({ ...teamData, expanded: true, subRows: r });
      });

      let includedTierOneParentTeams = selectedTierTwoTeam.filter((team) => includedTierTwoParentTeams.includes(team.id)).map((team) => team.parent_id);
      includedTierOneParentTeams = [...new Set(includedTierOneParentTeams)];
      includedTierOneParentTeams.forEach((id) => {
        const r = tempTwoTier.filter((team) => team.parent_id === id);
        const teamData = selectedTierOneTeam.find((team) => team.id === id);
        teams.push({ ...teamData, expanded: true, subRows: r });
      });
    }
    this.teamsList = teams;
    this.isTeamsListLoading = false;
  }

  // used when user clicks '-' button
  async collapseTeams(id, tierId, path) {
    let teams = this.teamsList;
    if (tierId === 1) {
      teams = teams.map((team) => (team.id === id ? { ...team, expanded: false } : team));
    } else if (tierId === 2) {
      const pathArr = path.split('/').filter((num) => !isNaN(Number(num)) && num).map((num) => Number(num));
      const [tierOneTeamId, tierTwoTeamId] = pathArr;
      teams = teams.map((team) => {
        if (team.id === tierOneTeamId) {
          team.subRows = team.subRows.map((te) => {
            if (te.id === tierTwoTeamId) {
              te = { ...te, expanded: false };
            }
            return te;
          });
        }
        return team;
      });
    } else if (tierId === 3) {
      const pathArr = path.split('/').filter((num) => !isNaN(Number(num)) && num).map((num) => Number(num));
      const [tierOneTeamId, tierTwoTeamId, tierThreeTeamId] = pathArr;
      teams = teams.map((team) => {
        if (team.id === tierOneTeamId) {
          team.subRows = team.subRows.map((te) => {
            if (te.id === tierTwoTeamId) {
              if (te.subRows) {
                te.subRows = te.subRows.map((t) => {
                  if (t.id === tierThreeTeamId) {
                    t = { ...t, expanded: false };
                  }
                  return t;
                });
              }
            }
            return te;
          });
        }
        return team;
      });
    }
    this.teamsList = teams;
  }

  clearTeamsData() {
    this.totalTeamsCount = 0;
    this.teamsList = [];
    this.tierTwoTeams = [];
    this.tierThreeTeams = [];
    this.isTeamsListOverviewLoading = false;
    this.isTeamsListLoading = false;
  }

  async createNewTeam(teamData) {
    const { data } = await Api.createNewTeam(teamData);

    if (data && data.id) {
      notificationsStore.createNotification('Team added successfully');

      return true;
    }
  }

  async updateTeam(teamData) {
    const { data } = await Api.updateTeam(teamData);

    if (data && data.id) {
      notificationsStore.createNotification('Team updated successfully');

      return true;
    }
  }

  async deleteTeam(teamID) {
    await Api.deleteTeam(teamID);

    notificationsStore.createNotification('Team deleted successfully');
    return true;
  }
}

decorate(TeamsStore, {
  teamsList: observable,
  totalTeamsCount: observable,
  isTeamsListLoading: observable,
  isTeamsListOverviewLoading: observable,
  tierOneTeams: observable,
  tierTwoTeams: observable,
  tierThreeTeams: observable,

  clearTeamsData: action,

  collapseTeams: action,
  getAllFilteredTeamsListing: action,
  fetchFilteredTeams: action,
  getAllTeamsOverviewData: action,
  getAllTeamsListing: action,

  createNewTeam: action,
  updateTeam: action,
  updateTeamName: action,
  deleteTeam: action,
});

export default new TeamsStore();
