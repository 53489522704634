// @flow
import { decorate, observable, action } from 'mobx';
import _ from 'lodash';
import BrightApi from '../../api';
import type { LearningPathway } from 'bright';
import baseStore from '../base/BaseStore';
import resourceStore from '../resource/ResourceStore';
import lessonStore from '../lesson/LessonStore';

class LearningPathwayStore {
  learningPathways: Array<LearningPathway> = [];
  creatingLearningPathway: Boolean;
  selectedLearningPathway: LearningPathway

  async getLearningPathways(force = false) {
    if (this.learningPathways.length > 0 && !force) {
      return this.learningPathways;
    }

    const { data } = await BrightApi.getLearningPathways();
    this.learningPathways = data.map(d => {
      d.systemVariations = d.systemVariationValues.map(s => baseStore.systemVariations.find(sv => sv.id === parseInt(s, 10)));
      d.brands = d.brandValues.map(s => baseStore.brands.find(sv => sv.id === parseInt(s, 10)));
      d.jobFunctions = d.jobFunctionValues.map(s => baseStore.jobFunctions.find(sv => sv.id === parseInt(s, 10)));
      d.regions = d.regionValues.map(s => baseStore.regions.find(sv => sv.id === parseInt(s, 10)));
      d.courses = _.compact(d.courseOrderMeta.map(s => resourceStore.resources.find(sv => sv.id === parseInt(s, 10))));

      return d;
    });

    return this.learningPathways;
  }

  async deleteLearningPathway(learningPathwayId) {
    const created = await BrightApi.deleteLearningPathway(learningPathwayId);
    this.learningPathways = [];
    await this.getLearningPathways();

    return created.data;
  }

  async getLearningPathway(learningPathwayId) {
    if (this.selectedLearningPathway != null && learningPathwayId === this.selectedLearningPathway.id) {
      return this.selectedLearningPathway;
    }

    const { data } = await BrightApi.getLearningPathway(learningPathwayId);

    data.resources = data.resources.map(s => resourceStore.resources.find(sv => sv.id === s.id));
    this.selectedLearningPathway = data;
  }

  setSelectedLearningPathway(selectedLearningPathway) {
    this.selectedLearningPathway = selectedLearningPathway;
  }

  async createLearningPathway(lp) {
    lp.brands = lp.brands.map(b => b.id);
    lp.jobFunctions = lp.jobFunctions.map(b => b.id);
    lp.systemVariations = lp.systemVariations.map(b => b.id);
    lp.regions = lp.regions.map(b => b.id);
    lp.languages = lp.languages.map(b => b.id);

    this.creatingLearningPathway = true;
    try {
      const created = await BrightApi.createLearningPathway(lp);
      this.learningPathways = [];
      await this.getLearningPathways();
      this.creatingLearningPathway = false;

      return created.data;
    } catch (e) {
      this.creatingLearningPathway = false;
    }
  }

  getDuration(lp) {
    const resources = _.compact(lp.courseOrderMeta.map(r => resourceStore.resources.find(resource => resource.id === parseInt(r, 10))));
    const allLessons = _.uniq(_.flatten(resources.map(r => r.lessonOrderMeta.map(l => parseInt(l, 10)))));

    return lessonStore.lessons.filter(l => allLessons.indexOf(l.id) !== -1).reduce((prev, cur) => {
      if (cur.duration && !isNaN(cur.duration)) {
        return prev + parseFloat(cur.duration, 10);
      }

      return prev;
    }, 0);
  }
}

decorate(LearningPathwayStore, {
  learningPathways: observable,
  selectedLearningPathway: observable,
  getLearningPathways: action,
  createLearningPathway: action,
  updateLearningPathways: action,
  setSelectedLearningPathway: action
});

export default new LearningPathwayStore();
