// @flow
import { decorate, observable, action } from 'mobx';
import BrightApi from '../../api';
import type { Location } from 'bright';

class LocationStore {
  locations: Array<Location> = [];
  selectedLocation: Location

  async getLocations() {
    const { data } = await BrightApi.getLocations();
    this.locations = data;
  }

  async getLocationByCode(locationCode) {
    const { data } = await BrightApi.getLocationByCode(locationCode);
    this.selectedLocation = data;
  }

  setSelectedLocation(selectedLocation) {
    this.selectedLocation = selectedLocation;
  }
}

decorate(LocationStore, {
  locations: observable,
  getLocations: action,
  getLocationByCode: action,
  setSelectedLocation: action,
  selectedLocation: observable
});

export default new LocationStore();
