// @flow
import {
  action,
  decorate,
  computed,
  observable,
} from 'mobx';
import _ from 'lodash';
import uuidv4 from 'uuid/v4';

import Api from '../../api';
import config from '../../config';
import CanvasStore from '../canvas/CanvasStore';
import { turnTextPixelToNumber } from '../../util/helpers';

const isProd = config.application === 'bright-prod';

class ScreenStore {
  constructor() {
    this.screens = [];
    this.screensTypeahead = [];
    this.screensTypeaheadLimit = 0;
    this.screensLimit = 0;
    this.creatingResource = false;
    this.fetchScreenState = null;
  }

  getScreens = async () => {
    const { data } = await Api.getScreens();
    const screenGroups = await CanvasStore.getCanvases();
    this.screens = _.sortBy(
      data.map((d) => {
        const foundAsStartingScreen = screenGroups.some(
          (sg) => _.get(sg, 'firstScreenInfo.id') === d.id,
        );
        d.startingScreen = foundAsStartingScreen;

        if (isProd) {
          d.canvas.src = d.canvas.src
            .replace('admin-dev', 'app')
            .replace('admin.learnwithbright.com', 'app.learnwithbright.com');
        }

        return d;
      }),
      [(screen) => (screen.name || '').toString().toLowerCase()],
      ['desc'],
    );
  };

  getScreensPaginated = async ({ search, page, pageSize }) => {
    const screenGroups = await CanvasStore.getCanvases();

    const {
      data: { rows: screens, count },
    } = await Api.getScreensPaginated({ search, page, pageSize });

    const parsedScreens = screens.map((screen) => {
      const foundAsStartingScreen = screenGroups.some(
        (sg) => _.get(sg, 'firstScreenInfo.id') === screen.id,
      );

      screen.startingScreen = foundAsStartingScreen;

      if (isProd) {
        screen.canvas.src = screen.canvas.src
          .replace('admin-dev', 'app')
          .replace('admin.learnwithbright.com', 'app.learnwithbright.com');
      }

      return screen;
    });

    this.screensTypeahead = page
      ? [...this.screensTypeahead.slice(), ...parsedScreens]
      : parsedScreens;

    this.screensByPage = screens;
    this.screensTypeaheadLimit = count;
  };

  getScreensForScreenGroup = async (screenGroupId) => {
    const {
      data: { rows: screens, count },
    } = await Api.getScreensPaginated({ screenGroupId });

    const parsedScreens = screens.map((screen) => {
      if (isProd) {
        screen.canvas.src = screen.canvas.src
          .replace('admin-dev', 'app')
          .replace('admin.learnwithbright.com', 'app.learnwithbright.com');
      }
      return screen;
    });

    this.screens = parsedScreens;
    this.screensLimit = count;

    return parsedScreens;
  };

  getScreen = async (screenId) => {
    try {
      this.fetchScreenState = null;

      const { data } = await Api.getScreen(screenId);

      if (isProd && data.canvas && data.canvas.src) {
        data.canvas.src = data.canvas.src
          .replace('admin-dev', 'app')
          .replace('admin.learnwithbright.com', 'app.learnwithbright.com');
      }

      this.currentScreen = {
        ...data,
        layers: data.screenLayers.map((layer) => ({
          id: layer.id || uuidv4(),
          ...layer,
        })),
        screenLayers: data.screenLayers,
        deletedLayers: [],
      };

      return this.currentScreen;
    } catch (e) {
      this.fetchScreenState = e?.response?.status;
    }
  };

  saveScreen = async (screen = this.currentScreen, screens = this.screens) => {
    this.creatingResource = true;
    const {
      layers,
      screenLayers,
      ...rest
    } = screen;

    const updatedScreen = await Api.updateScreen({
      ...rest,
      layers: layers.map(({ id, ...layer }) => (
        Number.isInteger(id) ? { ...layer, id } : layer
      )),
    }, {
      screenId: screen.id,
      type: 'metadata',
    });

    const screenIndex = screens.findIndex((sc) => sc.id === screen.id);

    const currentScreen = {
      ...rest,
      layers: updatedScreen.data.screenLayers,
      screenLayers: updatedScreen.data.screenLayers,
      deletedLayers: [],
    };

    if (screen.id === this.currentScreen?.id) {
      this.currentScreen = currentScreen;
    }

    const updatedScreens = [
      ...screens.slice(0, screenIndex),
      currentScreen,
      ...screens.slice(screenIndex + 1),
    ];

    this.screens = updatedScreens;

    this.creatingResource = false;

    return updatedScreens;
  };

  cloneScreen = async (canvasId) => {
    this.creatingResource = true;

    const layers = this.currentScreen?.screenLayers?.map((layer) => (
      {
        ...layer,
        id: null,
        extra: {
          ...layer.extra,
          uuid: uuidv4(),
        },
      }
    ));

    const cloned = {
      ...this.currentScreen,
      ...(canvasId && { canvasId, canvas_id: canvasId }), // clone from another canvas case
      uuid: uuidv4(),
      name: `${this.currentScreen.name} Clone`,
      screenLayers: layers,
      layers,
    };

    delete cloned.id;

    const { data: clonedScreen } = await Api.createScreen(cloned);

    this.creatingResource = false;

    return clonedScreen;
  };

  deleteScreen = async (screenId) => {
    await Api.deleteScreen(screenId);

    await this.getScreens();
  };

  setLayerPosition = (layer, d) => {
    this.currentScreen.layers = this.currentScreen.layers.map((l) => {
      if (l.extra.uuid === layer.extra.uuid) {
        l.position.x = d.x;
        l.position.y = d.y;
      }

      return l;
    });
  }

  setLayerSize = (layer, d) => {
    this.currentScreen.layers = this.currentScreen.layers.map((l) => {
      if (l.extra.uuid === layer.extra.uuid) {
        l.size.w = d.width;
        l.size.h = d.height;
      }

      return l;
    });
  }

  reorderLayer(idx, count) {
    const swapArrayLocations = (arr, index1, index2) => {
      [arr[index1], arr[index2]] = [arr[index2], arr[index1]];
    };

    swapArrayLocations(this.currentScreen.layers, idx, idx + count);
  }

  updateCurrentScreen(path, value) {
    _.set(this.currentScreen, path, value);
  }

  updateLayer(uuid, path, value) {
    this.currentScreen.layers = this.currentScreen.layers.map((l) => {
      if (l.extra.uuid === uuid) {
        _.set(l, path, value);
      }

      return l;
    });
  }

  updateGroupInfo = (uuid, path, value) => {
    this.currentScreen.layers = this.currentScreen.layers.map((l) => {
      if (l.group?.uuid === uuid) {
        _.set(l.group, path, value);
      }

      return l;
    });
  }

  addLayer = (layer) => {
    const newLayerId = uuidv4();

    const newLayer = {
      ...layer,
      id: newLayerId,
      extra: {
        ...layer.extra,
        uuid: newLayerId,
      },
    };

    const updatedLayers = [{ ...newLayer }, ...this.currentScreen.layers];

    this.currentScreen.layers = updatedLayers;
    this.currentScreen.screenLayers = updatedLayers;

    return newLayer;
  };

  deleteLayer = (uuid) => {
    this.currentScreen.layers = this.currentScreen.layers.filter(
      (l) => {
        if (l.extra.uuid === uuid && typeof l.id === 'number') {
          this.currentScreen.deletedLayers = [...this.currentScreen.deletedLayers, l.id];
        }

        return l.extra.uuid !== uuid;
      },
    );

    return this.currentScreen.layers;
  };

  deleteLayers = (uuids) => {
    this.currentScreen.layers = this.currentScreen.layers.filter(
      (l) => {
        if (uuids.includes(l.extra.uuid) && typeof l.id === 'number') {
          this.currentScreen.deletedLayers = [...this.currentScreen.deletedLayers, l.id];
        }

        return !uuids.includes(l.extra.uuid);
      },
    );

    return this.currentScreen.layers;
  };

  updateLayers = (layers) => {
    this.currentScreen = {
      ...this.currentScreen,
      layers: [...layers],
      screenLayers: [...layers],
    };
  };

  insertLayersAtIndex = (layers, newLayers, index) => {
    const updatedLayers = [
      ...layers.slice(0, index),
      ...newLayers,
      ...layers.slice(index),
    ];

    this.currentScreen = {
      ...this.currentScreen,
      layers: [...updatedLayers],
      screenLayers: [...updatedLayers],
    };
  };

  getLayerGroups = () => {
    const groups = this.currentScreen.layers
      .filter((layer) => layer.group)
      .reduce((acc, layer) => {
        const group = acc.find((group) => group.uuid === layer.group.uuid);

        if (group) {
          group.layers.push(layer);
          group.items.push(layer);
        } else {
          acc.push({
            type: 'group',
            name: layer.group.name,
            uuid: layer.group.uuid,
            id: layer.group.uuid,
            items: [layer],
            layers: [layer],
            positionX: layer.position.x,
            positionY: layer.position.y,
            width: turnTextPixelToNumber(layer.size.w),
            height: turnTextPixelToNumber(layer.size.h),
          });
        }

        return acc;
      }, []);

    return groups;
  }

  getLayersNotNormalized = () => {
    const groups = this.currentScreen.layers
      .reduce((acc, layer) => {
        if (!layer?.group) {
          acc.push({
            ...layer,
            group: null,
            groupId: null,
            group_id: null,
          });

          return acc;
        }

        const group = acc.find((group) => group.uuid === layer.group.uuid);

        if (group) {
          group.layers.push(layer);
          group.items.push(layer);
        } else {
          acc.push({
            type: 'group',
            name: layer.group.name,
            uuid: layer.group.uuid,
            id: layer.group.uuid,
            items: [layer],
            layers: [layer],
            positionX: layer.position.x,
            positionY: layer.position.y,
            width: turnTextPixelToNumber(layer.size.w),
            height: turnTextPixelToNumber(layer.size.h),
          });
        }

        return acc;
      }, []);

    return groups;
  }

  getNomalizedLayers = (groupsOrLayers) => {
    const layers = groupsOrLayers.reduce((acc, groupOrLayer) => {
      if (groupOrLayer.type === 'group') {
        return [...acc, ...groupOrLayer.items.map((item) => ({
          ...item,
          group: {
            name: groupOrLayer.name,
            uuid: groupOrLayer.uuid,
          },
        }))];
      }

      return [...acc, {
        ...groupOrLayer,
        group: null,
        groupId: null,
        group_id: null,
      }];
    }, []);

    return layers;
  }

  createGroupOfLayers = (layers) => {
    const group = {
      name: 'New Group',
      uuid: uuidv4(),
    };

    let minGroupIndex = Infinity;

    // Reorder the layers so that the grouped layers are together

    const layersWithGroup = this.currentScreen.layers.reduce((acc, layer, index) => {
      if (layers.find(l => l.id === layer.id)) {
        // If the layer is part of the group, update minGroupIndex and add to the grouped part.
        minGroupIndex = Math.min(minGroupIndex, index);
        acc.grouped.push({ ...layer, group });
      } else {
        // If not part of the group, just add to the non-grouped part.
        acc.nonGrouped.push(layer);
      }
      return acc;
    }, { grouped: [], nonGrouped: [] });

    // Concatenate non-grouped layers and grouped layers starting from the minimum group index
    const reorderedLayers = [
      ...layersWithGroup.nonGrouped.slice(0, minGroupIndex),
      ...layersWithGroup.grouped,
      ...layersWithGroup.nonGrouped.slice(minGroupIndex),
    ];

    console.log('reorderedLayers', layers, reorderedLayers);

    // Update the current screen with the reordered layers
    this.currentScreen = {
      ...this.currentScreen,
      layers: reorderedLayers,
      screenLayers: reorderedLayers,
    };
  }

  updateGroupData = (group) => {
    const layers = this.currentScreen.layers.map((l) => {
      if (l.group?.uuid === group.uuid) {
        return {
          ...l,
          group: {
            ...l.group,
            ...group,
          },
        };
      }

      return l;
    });

    this.currentScreen = {
      ...this.currentScreen,
      layers,
      screenLayers: layers,
    }
  };

  unGroupLayers = (layers) => {
    const layersWithoutGroups = this.currentScreen.layers.map((l) => {
      if (layers.find((layer) => layer.id === l.id)) {
        return {
          ...l,
          group: null,
          groupId: null,
          group_id: null,
        };
      }

      return l;
    });

    this.currentScreen = {
      ...this.currentScreen,
      layers: layersWithoutGroups,
      screenLayers: layersWithoutGroups,
    };
  }

  deleteGroup = (groupUuid) => {
    this.currentScreen = {
      ...this.currentScreen,
      layers: this.currentScreen.layers.filter((l) => l.group?.uuid !== groupUuid),
    };
  }

  get getCurrentScreen() {
    return this.currentScreen;
  }

  get jumpLocations() {
    return (this.screens || []).filter(
      (s) => s.id !== (this.currentScreen || {}).id,
    );
  }

  async updateScreenBackground(uploadData) {
    const { data } = await Api.updateScreen(uploadData, {
      screenId: this.currentScreen.id,
      type: 'canvas',
    });
    this.currentScreen = {
      ...this.currentScreen,
      canvas: data.canvas,
    };
  }

  updateScreens = (screens) => {
    this.screens = screens;
  };

  updateScreen = (screen) => {
    this.currentScreen = screen;
  };
}

decorate(ScreenStore, {
  getScreens: action,
  getScreen: action,
  updateScreens: action,
  saveScreen: action,
  updateScreenBackground: action,
  screens: observable,
  currentScreen: observable,
  getCurrentScreen: computed,
  jumpLocations: computed,
  updateLayers: action,
  updateLayer: action,
  deleteLayer: action,
  deleteLayers: action,
  insertLayersAtIndex: action,
  screensByPage: observable,
  updateGroupInfo: action,
  createGroupOfLayers: action,
  getLayersNotNormalized: action,
  deleteGroup: action,
});

export default new ScreenStore();
