// @flow
import { decorate, observable, action } from 'mobx';
import BrightApi from '../../api';
import type { Lesson } from 'bright';
import _ from 'lodash';

class LessonStore {
  lessons: Array<Lesson> = [];
  durationByResourceId = {};
  lessonDataLoaded: Boolean = false;

  async getLessons(force = false) {
    if (this.lessons.length > 0 && !force) {
      return this.lessons;
    }

    const { data } = await BrightApi.getLessons(true);
    this.lessons = data;

    _.each(data, d => {
      if (!this.durationByResourceId[d.resourceId]) {
        this.durationByResourceId[d.resourceId] = 0;
      }
      if (!isNaN(parseFloat(d.duration))) {
        this.durationByResourceId[d.resourceId] = this.durationByResourceId[d.resourceId] + parseFloat(d.duration);
      }
    });

    this.lessonDataLoaded = true;
  }

  async createLesson(lesson) {
    this.creatingLesson = true;
    try {
      const created = await BrightApi.createLesson(lesson);
      this.courses = [];
      await this.getLessons(true);
      this.creatingLesson = false;

      return created.data;
    } catch (e) {
      this.creatingLesson = false;
    }
  }

  async updateLesson(lesson) {
    this.creatingLesson = true;
    try {
      const created = await BrightApi.updateLesson(lesson);
      this.courses = [];
      await this.getLessons(true);
      this.creatingLesson = false;

      return created.data;
    } catch (e) {
      this.creatingLesson = false;
    }
  }

  async deleteLesson(lessonId) {
    this.deletingLesson = true;
    try {
      await BrightApi.deleteLesson(lessonId);
      this.courses = [];
      await this.getLessons(true);
    } catch (e) {
      this.deletingLesson = false;
    }
  }

  getDuration(lessonOrderMeta) {
    return this.lessons.filter(l => lessonOrderMeta.map(l => parseInt(l, 10)).indexOf(l.id) !== -1).reduce((prev, cur) => {
      if (cur.duration && !isNaN(cur.duration)) {
        return prev + parseFloat(cur.duration, 10);
      }

      return prev;
    }, 0);
  }
}

decorate(LessonStore, {
  lessons: observable,
  lessonDataLoaded: observable,
  getLessons: action,
  createLesson: action,
  updateLesson: action
});

export default new LessonStore();
