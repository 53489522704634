// @flow
import { action, decorate, observable } from 'mobx';
import Api from '../../api';

class SkillsAIStore {
  skills = [];

  skillCategories = [];

  categorySkills = [];

  getSkills = async () => {
    const { data } = await Api.getTags('skill');
    this.skills = data;
  }

  getSkillCategories = async () => {
    const { data } = await Api.getTagCategories();
    this.skillCategories = data;
  }

  async getAIRuleHistory(momentId) {
    const { data } = await Api.getAIRuleHistory(momentId);
    this.ruleHistory = data;
  }

  clearAIRuleHistory() {
    this.ruleHistory = null;
  }
}

decorate(SkillsAIStore, {
  getSkills: action,
  getSkillCategories: action,
  skills: observable,
  skillCategories: observable,
  getAIRuleHistory: action,
  ruleHistory: observable,
  clearAIRuleHistory: action,
});

export default new SkillsAIStore();
