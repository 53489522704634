// @flow
import { decorate, observable, action } from 'mobx';
import Api from '../../api';

import notificationsStore from '../notifications/NotificationsStore';

class AssignmentStore {
  constructor() {
    this.totalAssignmentsCount = 0;
    this.assignmentsList = [];

    this.isAssignmentsListOverviewLoading = false;
    this.isAssignmentsListLoading = false;

    this.getAssignmentByIdData = null;
    this.isGetAssignmentByIdLoading = false;

    this.totalResourcesCount = 0;
    this.resourcesList = [];

    this.isResourcesListOverviewLoading = false;
    this.isResourcesListLoading = false;
  }

  async getAssignmentsOverviewData(queryParams) {
    this.isAssignmentsListOverviewLoading = true;

    const { data } = await Api.getAssignmentsOverviewData(queryParams);
    this.isAssignmentsListOverviewLoading = false;

    if (data) {
      this.totalAssignmentsCount = data.totalAssignmentsCount;
    }
  }

  async getAssignmentById(assignmentID) {
    this.isGetAssignmentByIdLoading = true;

    const { data } = await Api.getAssignmentById(assignmentID);
    this.isGetAssignmentByIdLoading = false;

    if (data) {
      this.getAssignmentByIdData = data;
    }
  }

  async clearAssignmentByIdData() {
    this.getAssignmentByIdData = null;
    this.isGetAssignmentByIdLoading = false;
  }

  async getAssignmentsListing(queryParams) {
    this.isAssignmentsListLoading = true;

    const { data } = await Api.getAssignmentsListing(queryParams);
    this.isAssignmentsListLoading = false;

    if (data) {
      this.assignmentsList = data;
    }
  }

  clearAssignmentsData() {
    this.totalAssignmentsCount = 0;
    this.assignmentsList = [];
    this.isAssignmentsListOverviewLoading = false;
    this.isAssignmentsListLoading = false;
  }

  async createNewAssignment(assignmentData) {
    const { data } = await Api.createNewAssignment(assignmentData);

    if (data && data.id) {
      notificationsStore.createNotification('Assignment added successfully');

      return true;
    }
  }

  async updateAssignment(assignmentData) {
    const { data } = await Api.updateAssignment(assignmentData);

    if (data && data.id) {
      notificationsStore.createNotification('Assignment updated successfully');

      return true;
    }
  }

  async deleteAssignment(assignmentID) {
    await Api.deleteAssignment(assignmentID);

    notificationsStore.createNotification('Assignment deleted successfully');
    return true;
  }

  async getResourceListOverviewData(filterParams) {
    this.isResourcesListOverviewLoading = true;

    const { data } = await Api.getResourceListOverviewData(filterParams);
    this.isResourcesListOverviewLoading = false;

    if (data) {
      this.totalResourcesCount = data.totalResourcesCount;
    }
  }

  async getResourceListing(filterParams, paginationParams, shouldAppend) {
    this.isResourcesListLoading = true;

    const { data } = await Api.getResourceListing(filterParams, paginationParams);
    this.isResourcesListLoading = false;

    if (data) {
      if (shouldAppend) {
        this.resourcesList = [...this.resourcesList, ...data];
      } else {
        this.resourcesList = data;
      }
    }
  }

  clearResourcesData() {
    this.totalResourcesCount = 0;
    this.resourcesList = [];
    this.isResourcesListOverviewLoading = false;
    this.isResourcesListLoading = false;
  }
}

decorate(AssignmentStore, {
  assignmentsList: observable,
  totalAssignmentsCount: observable,
  isAssignmentsListOverviewLoading: observable,
  isAssignmentsListLoading: observable,

  clearAssignmentsData: action,

  getAssignmentsListOverviewData: action,
  getAssignmentsListing: action,

  getAssignmentByIdData: observable,
  isGetAssignmentByIdLoading: observable,
  getAssignmentById: action,
  clearAssignmentByIdData: action,

  createNewAssignment: action,
  updateAssignment: action,
  deleteAssignment: action,

  totalResourcesCount: observable,
  resourcesList: observable,
  isResourcesListOverviewLoading: observable,
  isResourcesListLoading: observable,
  getResourceListOverviewData: action,
  getResourceListing: action,

  clearResourcesData: action,
});

export default new AssignmentStore();
