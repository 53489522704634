import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'rc-slider/assets/index.css';
import './styles/fonts.css';
import './styles/global.css';
import Root from './containers/Root';
import * as serviceWorker from './service-worker';

const render = (Component) => {
  ReactDOM.render(
    <Component />,
    document.getElementById('root'),
  );
};

render(Root);

const { hot } = module;

if (hot) {
  hot.accept('./containers/Root', () => {
    render(require('./containers/Root').default);
  });
}

serviceWorker.unregister();
