export const application = 'bright-academy-dev';

export const apiURL = '/bright/api';

export const gaId = 'UA-91016801-4';

export const wsURL = 'https://app-dev.learnwithbright.com';

export const rootUrl = 'https://admin-dev.learnwithbright.academy';

export const academyURL = 'https://app-dev.learnwithbright.academy';
