// @flow
import { decorate, observable, action } from 'mobx';
import BrightApi from '../../api';

class CustomerStore {
  customers = [];

  selectedCustomer = null;

  creatingCustomer = false;

 files = [];

 async getCustomers() {
   const { data } = await BrightApi.getCustomers();
   this.customers = data;

   return this.customers;
 }

 async getCustomer(customerId) {
   const { data } = await BrightApi.getCustomer(customerId);
   this.selectedCustomer = data;
 }

 async getCustomerUploadFiles() {
   const files = await BrightApi.getCustomerUploadFiles();

   this.files = files.data;

   return files.data;
 }

 async uploadCustomerFile(data) {
   const file = await BrightApi.uploadCustomerFile(data);

   return file;
 }
}

decorate(CustomerStore, {
  customers: observable,
  selectedCustomer: observable,
  creatingCustomer: observable,
  getCustomers: action,
  deleteMoment: action,
  getCustomerUploadFiles: action,
  uploadCustomerFile: action,
});

export default new CustomerStore();
