/* eslint-disable class-methods-use-this */
// @flow
import { decorate, observable, action } from 'mobx';
import Api from '../../api';

import notificationsStore from '../notifications/NotificationsStore';

class UserManagementStore {
  constructor() {
    this.totalUsersCount = 0;
    this.filteredUsersCount = 0;
    this.usersList = [];
    this.isUsersListOverviewLoading = false;
    this.isUsersListLoading = false;

    this.formOptions = {};
    this.isFormOptionsLoading = false;

    this.cognitoPasswordPolicies = {};
  }

  async setCognitoPasswordPolicies() {
    const { data } = await Api.getCognitoPasswordPolicy();

    this.cognitoPasswordPolicies = data?.passwordPolicies || {};
  }

  async getUserFormOptionsData() {
    this.isFormOptionsLoading = true;

    const { data } = await Api.getUserFormOptionsData();
    this.isFormOptionsLoading = false;

    if (data) {
      this.formOptions = data.formOptions;
    }
  }

  async getUsersOverviewData(queryParams = {}) {
    this.isUsersListOverviewLoading = true;

    const { data } = await Api.getUsersOverviewData(queryParams);
    this.isUsersListOverviewLoading = false;

    if (data) {
      this.totalUsersCount = data.totalUsersCount;
    }
  }

  async getUsersListing(queryParams) {
    this.isUsersListLoading = true;

    const { data } = await Api.getUsersListing(queryParams);

    if (data) {
      this.usersList = data.users || [];
      this.filteredUsersCount = data.totalCount || 0;
    }

    this.isUsersListLoading = false;
  }

  async downloadUsersListing(queryParams) {
    const { data } = await Api.downloadUsersListing(queryParams);

    return data;
  }

  async clearTierTeams() {
    this.formOptions = {
      ...this.formOptions,
      tierTwoTeams: [],
      tierThreeTeams: [],
      tierFourTeams: [],
    };
  }

  async getTierTeams({ tierTwoTeams, tierThreeTeams, tierFourTeams }) {
    this.formOptions = {
      ...this.formOptions,
      tierTwoTeams,
      tierThreeTeams,
      tierFourTeams,
    };
  }

  clearUserManagementStoreData() {
    this.totalUsersCount = 0;
    this.usersList = [];
    this.isUsersListOverviewLoading = false;
    this.isUsersListLoading = false;

    this.isFormOptionsLoading = false;
  }

  async createNewUser(userData) {
    try {
      const response = await Api.createNewUser(userData);

      if (response?.data.id) {
        notificationsStore.createNotification('User added successfully');

        return { created: true };
      }

      if (response === 409) {
        return { duplicated: true };
      }

      return response.data;
    } catch (error) {
      const { data } = error.response;
      return { created: false, message: data.message };
    }
  }

  async uploadAvatar(avatarFileData) {
    const { data } = await Api.uploadUserAvatar(avatarFileData);

    return data;
  }

  async updateUser(userData) {
    const { data } = await Api.updateUserViaUserManagement(userData);

    if (data && data.id) {
      notificationsStore.createNotification('User updated successfully');

      return true;
    }
  }

  async bulkUpdateUsers(userIDs, data) {
    for (let idIndex = 0; idIndex < userIDs.length; idIndex++) {
      await Api.updateUserViaUserManagement({
        id: userIDs[idIndex],
        ...data,
      });
    }

    notificationsStore.createNotification(`${userIDs.length} user${userIDs.length >= 2 ? 's' : ''} updated successfully`);

    return true;
  }

  async deleteUsers(userIDs) {
    await Api.deleteUsers(userIDs);

    notificationsStore.createNotification('Users deleted successfully');
    return true;
  }

  async deleteUser(userID) {
    await Api.deleteUser(userID);

    notificationsStore.createNotification('User deleted successfully');
    return true;
  }

  async validateBulkCreateUsers(userListsToBulkCreate) {
    const { data } = await Api.validateBulkCreateUsers(userListsToBulkCreate);

    return data;
  }

  async bulkCreateUsers(userListsToBulkCreate) {
    const { data } = await Api.bulkCreateUsers(userListsToBulkCreate);

    return data;
  }
}

decorate(UserManagementStore, {
  usersList: observable,
  totalUsersCount: observable,
  isUsersListLoading: observable,
  isUsersListOverviewLoading: observable,
  filteredUsersCount: observable,
  cognitoPasswordPolicies: observable,

  setCognitoPasswordPolicies: action,
  clearUserManagementStoreData: action,

  getUsersOverviewData: action,
  getUsersListing: action,
  downloadUsersListing: action,
  getTierTeams: action,
  clearTierTeams: action,

  createNewUser: action,
  updateUser: action,
  bulkUpdateUsers: action,
  deleteUser: action,

  formOptions: observable,
  isFormOptionsLoading: observable,

  validateBulkCreateUsers: action,
  bulkCreateUsers: action,
  validateDoNotUpdateFile: action,
  uploadDoNotUpdateFile: action,
});

export default new UserManagementStore();
