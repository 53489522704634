// @flow
import { action, decorate, observable } from 'mobx';
import Api from '../../api';

class AnnouncementStore {
  announcements = [];

  hasNewAnnouncements = false;

  criticalAnnouncement = null;

  endOfAnnouncementsList = false;

  getAnnouncements = async () => {
    try {
      const lastIndex = this.announcements?.length || 0;
      const { data: announcements } = await Api.getAnnouncements(lastIndex);

      if (announcements.length === 0) {
        this.endOfAnnouncementsList = true;
      }
      this.announcements = [...this.announcements, ...announcements];

      this.hasNewAnnouncements = this.announcements.some(({ isRead }) => !isRead);

      this.criticalAnnouncement = this.announcements
        .find(({ critical, isRead }) => critical && !isRead);

      return this.announcements;
    } catch (err) {
      console.log('err', err);
      return null;
    }
  };

  markAnnouncementsAsRead = async () => {
    try {
      await Api.markAnnouncementsAsRead();
      this.hasNewAnnouncements = false;
      this.criticalAnnouncement = null;
      this.announcements = this.announcements.map((announcement) => ({
        ...announcement,
        isRead: true,
      }));
    } catch (err) {
      console.log('err', err);
    }
  }
}

decorate(AnnouncementStore, {
  announcements: observable,
  hasNewAnnouncements: observable,
  endOfAnnouncementsList: observable,
  getAnnouncements: action,
  markAnnouncementsAsRead: action,
});

export default new AnnouncementStore();
