// @flow
import { decorate, computed } from 'mobx';

class BaseStore {
  get roles() {
    return ['admin', 'coach', 'user'];
  }
}

decorate(BaseStore, {
  roles: computed,
});

export default new BaseStore();
