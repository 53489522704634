export const application = 'bright-dev-v2';

export const apiURL = '/api';

export const feUrl = '/frontend';

export const rootUrl = '/admin';

export const vimeoClientId = '15a9673c81d2f261fca256dcabec4c439e215a55';

export const vimeoRedirect = 'https://admin-dev-v2.learnwithbright.com/bright/api/vimeo/callback';

export const academyURL = 'https://app-dev.learnwithbright.academy';
