// @flow
import { decorate, observable, action } from 'mobx';
import BrightApi from '../../api';
import type { Revision } from 'bright';

class RevisionStore {
  revisions: Array<Revision> = [];

  async getRevisions(type, id, since = new Date(0)) {
    this.revisions = [];
    const { data } = await BrightApi.getRevisions(type, id, since);
    this.revisions = data;
  }
}

decorate(RevisionStore, {
  revisions: observable,
  getRevisions: action
});

export default new RevisionStore();
