/* eslint-disable class-methods-use-this */
import { decorate, observable, action } from 'mobx';
import Api from '../../api';

class BadgeStore {
  constructor() {
    this.totalBadgesCount = 0;
    this.badgesList = [];

    this.isBadgesListOverviewLoading = false;
    this.isBadgesListLoading = false;
  }

  async getBadgesListing(queryParams) {
    this.isBadgesListLoading = true;

    const { data } = await Api.getBadgesListing(queryParams);
    this.isBadgesListLoading = false;

    if (data) {
      this.badgesList = data.badges;
      this.totalBadgesCount = data.totalBadgesCount;
    }

    return data;
  }

  async createBadge(badgeData) {
    const { data } = await Api.createBadge(badgeData);

    return data;
  }

  async updateBadge(badgeData) {
    const { data } = await Api.updateBadge(badgeData);

    return data;
  }

  async deleteBadge(badgeId) {
    const { data } = await Api.deleteBadge(badgeId);

    return data;
  }

  clearBadgesData() {
    this.totalBadgesCount = 0;
    this.badgesList = [];
    this.isBadgesListOverviewLoading = false;
    this.isBadgesListLoading = false;
  }
}

decorate(BadgeStore, {
  badgesList: observable,
  totalBadgesCount: observable,
  isBadgesListOverviewLoading: observable,
  isBadgesListLoading: observable,

  clearBadgesData: action,

  getBadgesListOverviewData: action,
  createBadge: action,
  updateBadge: action,
  getBadgesListing: action,
});

export default new BadgeStore();
