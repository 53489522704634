// @flow
import { action, decorate, observable } from 'mobx';
import Api from '../../api';
import _ from 'lodash';

class ImageStore {
  images = [];
  creatingResource: false

  getImages = async () => {
    const { data } = await Api.getImages();
    this.images = data;
  }

  async getImage(imageId) {
    const { data } = await Api.getImage(imageId);
    this.currentImage = data;
  }

  async saveImage() {
    this.creatingResource = true;
    await Api.updateImage(this.currentImage);
    this.creatingResource = false;
  }

  async cloneImage() {
    this.creatingResource = true;
    const cloned = _.extend(this.currentImage, { name: `${this.currentImage.name} Clone` });
    delete cloned.id;

    const { data } = await Api.createImage(cloned);
    this.creatingResource = false;

    return data;
  }
}

decorate(ImageStore, {
  getImages: action,
  getImage: action,
  images: observable
});

export default new ImageStore();
