import { action, decorate, observable, computed } from 'mobx';
import Api from '../../api';

class TagStore {
  tags = [];
  tagCategories = [];
  creatingTag: false;
  selectedSkillCategory: null;
  categoryTags = [];

  getTags = async () => {
    const { data } = await Api.getTags();
    this.tags = data;
  }

  setSelectedSkillCategory = async (selectedSkillCategory) => {
    this.selectedSkillCategory = selectedSkillCategory;
  }

  updateTag = async (tag) => {
    this.creatingTag = true;
    await Api.updateTag(tag);
    this.creatingTag = false;
  }

  createTag = async (tag) => {
    this.creatingTag = true;
    await Api.createTag(tag, this.selectedSkillCategory.id);
    this.creatingTag = false;
  }

  getTagCategories = async () => {
    const { data } = await Api.getTagCategories();
    this.tagCategories = data;
  }

  updateTagCategory = async (tagCategory) => {
    this.creatingTag = true;
    await Api.updateTagCategory(tagCategory);
    this.creatingTag = false;
  }

  createTagCategory = async (tag) => {
    this.creatingTag = true;
    const { data: createdTagCategory } = await Api.createTagCategory(tag);
    this.creatingTag = false;
    return createdTagCategory;
  }

  deleteTagCategory = async (tagCategory) => {
    await Api.deleteTagCategory(tagCategory);
  }

  deleteTag = async (tag) => {
    await Api.deleteTag(tag);
  }

  get skillCategories() {
    return this.tagCategories;
  }

  get skills() {
    return this.tags.filter(t => t.type === 'skill');
  }

  get skillCategoryTags() {
    if (!this.selectedSkillCategory) {
      return [];
    }

    return this.tags.filter(t => t.categoryId === this.selectedSkillCategory.id);
  }
}

decorate(TagStore, {
  getTags: action,
  getTag: action,
  createTag: action,
  tags: observable,
  tagCategories: observable,
  skillCategories: computed,
  selectedSkillCategory: observable,
  skillCategoryTags: computed,
  skills: computed
});

export default new TagStore();
