// @flow
import { decorate, observable, action } from 'mobx';
import Api from '../../api';

import notificationsStore from '../notifications/NotificationsStore';

class WelcomePlacematStore {
  constructor() {
    this.welcomePlacemat = null;
    this.isWelcomePlacematLoading = true;
  }

  async getWelcomePlacemat() {
    this.isWelcomePlacematLoading = true;

    const { data } = await Api.getWelcomePlacemat();

    this.isWelcomePlacematLoading = false;

    if (data) {
      this.welcomePlacemat = data;
    }
  }

  async updateWelcomePlacemat(welcomePlacematData) {
    const { data } = await Api.updateWelcomePlacemat(welcomePlacematData);

    if (data && data.id) {
      notificationsStore.createNotification('Welcome placemat updated successfully');

      return true;
    }
  }

  clearWelcomePlacematData() {
    this.welcomePlacemat = null;
    this.isWelcomePlacematLoading = false;
  }
}

decorate(WelcomePlacematStore, {
  welcomePlacemat: observable,
  isWelcomePlacematLoading: observable,

  getWelcomePlacemat: action,
  updateWelcomePlacemat: action,
  clearWelcomePlacematData: action,
});

export default new WelcomePlacematStore();
