export const application = 'bright';

export const apiURL = 'http://localhost:5200/bright/api';

export const wsURL = 'http://localdev.learnwithbright.com:5200';

export const feUrl = 'http://localhost:3003';

export const rootUrl = 'http://localhost:3004';

export const vimeoClientId = '15a9673c81d2f261fca256dcabec4c439e215a55';

export const vimeoRedirect = 'http://localhost:5200/bright/api/vimeo/callback';

export const academyURL = 'http://localhost:3003';
