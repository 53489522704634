// @flow
import { decorate, observable, computed, action } from 'mobx';
import BrightApi from '../../api';
import type { Config } from 'bright';

class ConfigStore {
  config: Config;

  async getConfig() {
    if (this.config !== undefined) {
      return;
    }

    const { data } = await BrightApi.getConfig();
    this.config = data;
  }

  get isProd() {
    return this.config
      && this.config.env === 'production';
  }
}

decorate(ConfigStore, {
  config: observable,
  getConfig: action,
  isProd: computed
});

export default new ConfigStore();
