// @flow
import { decorate, observable, action, computed } from 'mobx';
import BrightApi from '../../api';
import userStore from '../user/UserStore';

class ProjectContentStore {
  projectContents = []
  creatingProjectContent = false;
  selectedProjectContent = null;

  async getProjectContents() {
    const { data } = await BrightApi.getProjectContent(userStore.currentProject);
    this.projectContents = data.map(d => {
      return d;
    });

    return this.projectContents;
  }

  async deleteProjectContent(projectContentId) {
    const created = await BrightApi.deleteProjectContent(projectContentId);
    this.projectContents = [];
    await this.getProjectContents();

    return created.data;
  }

  async getProjectContentById(projectContentId) {
    if (this.selectedProjectContent != null && projectContentId === this.selectedProjectContent.id) {
      return this.selectedProjectContent;
    }

    const { data } = await BrightApi.getProjectContentById(projectContentId);
    this.selectedProjectContent = data;
  }

  setSelectedProjectContent(selectedProjectContent) {
    this.selectedProjectContent = selectedProjectContent;
  }

  async createProjectContent(lp) {
    this.creatingProjectContent = true;
    try {
      const created = await BrightApi.createProjectContent(lp, userStore.currentProject);
      this.projectContents = [];
      await this.getProjectContents();
      this.creatingProjectContent = false;

      return created.data;
    } catch (e) {
      this.creatingProjectContent = false;
    }
  }

  async updateProjectContent(lp) {
    this.creatingProjectContent = true;
    try {
      const created = await BrightApi.updateProjectContent(lp);
      this.projectContents = [];
      await this.getProjectContents();
      this.creatingProjectContent = false;

      return created.data;
    } catch (e) {
      this.creatingProjectContent = false;
    }
  }

  get awards() {
    return this.projectContents.filter(c => c.content.type === 'award');
  }

  get partnerships() {
    return this.projectContents.filter(c => c.content.type === 'partnership');
  }
}

decorate(ProjectContentStore, {
  projectContents: observable,
  selectedProjectContent: observable,
  awards: computed,
  partnerships: computed,
  getProjectContents: action,
  createProjectContent: action,
  updateProjectContent: action,
  setSelectedProjectContent: action
});

export default new ProjectContentStore();
