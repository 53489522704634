// @flow
import { decorate, observable, action } from 'mobx';
import Api from '../../api';

class InvitationStore {
  invitations = []
  creatingInvitation = false;
  selectedInvitation = null;

  async getInvitations() {
    const { data } = await Api.getInvitations();
    this.invitations = data;

    return this.invitations;
  }

  async getInvitation(invitationId) {
    const { data } = await Api.getInvitation(invitationId);
    this.selectedInvitation = data;

    return data;
  }
  
  async updateInvitation(moment) {
    this.creatingInvitation = true;
    await Api.updateInvitation(moment);
    this.creatingInvitation = false;
  }

  async createInvitation(invitation) {
    this.creatingInvitation = true;
    const { data } = await Api.createInvitation(invitation);
    this.creatingInvitation = false;

    return data;
  }

  async deleteInvitation(invitationId) {
    const created = await Api.deleteInvitation(invitationId);
    this.invitations = [];
    await this.getInvitations();

    return created.data;
  }
}

decorate(InvitationStore, {
  invitations: observable,
  selectedInvitation: observable,
  getInvitations: action,
  deleteInvitation: action
});

export default new InvitationStore();
