// @flow
import { decorate, observable, action } from 'mobx';
import BrightApi from '../../api';
import type { Program } from 'bright';

class ProgramStore {
  programs: Array<Program> = [];
  selectedProgram: Program

  async getPrograms() {
    const { data } = await BrightApi.getPrograms();
    this.programs = data;
  }

  async getProgramByProgramId(programId) {
    const { data } = await BrightApi.getProgramByProgramId(programId);
    this.selectedProgram = data;
  }

  setSelectedProgram(selectedProgram) {
    this.selectedProgram = selectedProgram;
  }

  async updateProgram(program) {
    await BrightApi.updateProgram(program);
  }
}

decorate(ProgramStore, {
  programs: observable,
  getPrograms: action,
  getProgramByProgramId: action,
  updateProgram: action,
  setSelectedProgram: action,
  selectedProgram: observable
});

export default new ProgramStore();
