const config = {
  local: 'local',
  qa: 'qa',
  dev: 'dev',
  prod: 'prod',
  preprod: 'preprod',
  academy: 'academy',
  'academy-prod': 'academy-prod',
  'academy-local': 'academy-local',
  'academy-preprod': 'academy-preprod',
  'academy-dev': 'academy-dev',
  'dev-v2': 'dev-v2',
};

function getConfig(env) {
  const appConfig = env ? require(`./${config[env]}`) : require(`./${config.local}.js`);

  appConfig.env = env;

  return appConfig;
}

export default {
  isLocal: ['local', 'academy-local'].includes(process.env.REACT_APP_BUILD_ENV),
  isProd: process.env.REACT_APP_BUILD_ENV === 'prod',
  isDev: ['dev', 'preprod', 'academy', 'dev-v2'].includes(process.env.REACT_APP_BUILD_ENV),
  isAcademy: process.env.REACT_APP_BUILD_ENV.includes('academy'),
  ...getConfig(process.env.REACT_APP_BUILD_ENV),
};
