// @flow
import { decorate, observable, action } from 'mobx';
import Api from '../../api';

class ResourceStore {
  constructor() {
    this.resources = [];

    this.totalCount = 0;

    this.selectedResource = null;

    this.creatingResource = false;

    this.resourceDataLoaded = false;
  }

  getResources = async (queryParams = {}) => {
    this.resourceDataLoaded = false;

    const { data } = await Api.getResources(queryParams);

    if (data) {
      this.resources = data.resources;
      this.totalCount = data.totalCount;
      this.resourceDataLoaded = true;
      return this.resources;
    }
    this.resources = [];
    return [];
  }

  setSelectedResource(resource) {
    this.selectedResource = resource;
  }

  async getResource(resourceId) {
    const { data } = await Api.getResource(resourceId);
    this.selectedResource = data;
  }

  async createResource(resource) {
    this.creatingResource = true;

    try {
      const created = await Api.createResource(resource);
      this.resources = [];
      await this.getResources();
      this.creatingResource = false;

      return created.data;
    } catch (e) {
      throw new Error(e);
    } finally {
      this.creatingResource = false;
    }
  }

  async deleteResource(resourceId) {
    this.creatingResource = true;
    try {
      const created = await Api.deleteResource(resourceId);
      this.resources = [];
      await this.getResources();
      this.creatingResource = false;

      return created.data;
    } catch (e) {
      throw new Error(e);
    } finally {
      this.creatingResource = false;
    }
  }

  async deleteResources(resourceIds) {
    this.creatingResource = true;
    try {
      const created = await Api.deleteResources(resourceIds);
      this.resources = [];
      await this.getResources();
      this.creatingResource = false;

      return created.data;
    } catch (e) {
      throw new Error(e);
    } finally {
      this.creatingResource = false;
    }
  }

  async updateResource(resource) {
    delete resource.date_created;
    delete resource.date_updated;

    this.updatingResource = true;
    try {
      const created = await Api.updateResource(resource);
      this.resources = [];
      this.updatingResource = false;

      this.resources = this.resources.map((r) => {
        if (r.id !== created.data.id) {
          return r;
        }
        return created.data;
      });

      return created.data;
    } catch (e) {
      throw new Error(e);
    } finally {
      this.updatingResource = false;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async bulkTagResources({ selectedTeamIds, resourceIds }) {
    try {
      await Api.bulkTagResources({
        selectedTeamIds: selectedTeamIds.map(({ id }) => id),
        resourceIds,
      });

      return true;
    } catch (e) {
      throw new Error(e);
    }
  }

  clearResources() {
    this.resources = [];
    this.totalCount = 0;
    this.selectedResource = null;
    this.creatingResource = false;
    this.resourceDataLoaded = false;
  }

  async uploadCourseFile(resourceId, uploadData) {
    await Api.uploadCourseFile(resourceId, uploadData);
  }
}

decorate(ResourceStore, {
  resources: observable,
  resourceDataLoaded: observable,
  totalCount: observable,
  creatingResource: observable,
  getResource: action,
  getResources: action,
  getLessons: action,
  createResource: action,
  updateResource: action,
  bulkTagResources: action,
  setSelectedResource: action,
  deleteResource: action,
  deleteResources: action,
  clearResources: action,
});

export default new ResourceStore();
