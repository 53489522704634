export const application = 'bright-prod';

export const apiURL = '/bright/api';

export const gaId = 'UA-91016801-4';

export const wsURL = 'https://www.learnwithbright.com';

export const rootUrl = 'https://admin.learnwithbright.com';

export const academyURL = 'https://learnwithbright.academy';
