// @flow
import { decorate, observable, action } from 'mobx';
import Api from '../../api';

class CertificationStore {
  certifications;

  totalCount;

  creatingCertification = false;

  selectedCertification = null;

  fetchScreenState = null;

  constructor() {
    this.certifications = [];
    this.totalCount = 0;
  }

  getCertifications = async (queryParams) => {
    const { data } = await Api.getCertifications(queryParams);
    if (data) {
      this.certifications = data.certifications;
      this.totalCount = data.totalCount;
    }

    return this.certifications;
  }

  getCertification = async (certificationId) => {
    try {
      const { data } = await Api.getCertification(certificationId);

      this.selectedCertification = data;

      return data;
    } catch (e) {
      this.fetchScreenState = e.response.status;
    }
  }

  updateCertification = async (certification) => {
    this.creatingCertification = true;
    await Api.updateCertification(certification);
    this.creatingCertification = false;
  }

  createCertification = async (certification) => {
    this.creatingCertification = true;
    let data = null;
    try {
      ({ data } = await Api.createCertification(certification));
    } catch (error) {
      throw new Error(error);
    } finally {
      this.creatingCertification = false;
    }
    return data;
  }

  addBreakpoint(breakpoint) {
    this.selectedCertification.extra.breakpoints.push(breakpoint);
  }

  removeBreakpoint(breakpoint) {
    this.selectedCertification.extra.breakpoints = this.selectedCertification.extra.breakpoints.filter(b => {
      return b.uuid !== breakpoint.uuid
    });
  }

  deleteCertification = async (certificationId) => {
    const { data } = await Api.deleteCertification(certificationId);
    await this.getCertifications();

    return data;
  }

  deleteCertifications = async (certificationIds) => {
    await Api.deleteCertifications(certificationIds);
    await this.getCertifications();
  }

  // eslint-disable-next-line class-methods-use-this
  bulkTagCertifications = async ({ selectedTeamIds, certificationIds }) => {
    try {
      await Api.bulkTagCertifications({
        selectedTeamIds: selectedTeamIds.map(({ id }) => id),
        certificationIds,
      });

      return true;
    } catch (e) {
      throw new Error(e);
    }
  }
}

decorate(CertificationStore, {
  certifications: observable,
  selectedCertification: observable,
  getCertifications: action,
  createCertification: action,
  updateCertification: action,
  deleteCertifications: action,
  deleteCertification: action,
  addBreakpoint: action,
  bulkTagCertifications: action,
});

export default new CertificationStore();
