// @flow
import { decorate, computed, observable } from 'mobx';
import _ from 'lodash';

// text
// date
// select

// jump
//

class BuilderStore {
  constructor() {
    this.currentScreen = {
      name: 'Screen 1',
      canvas: {
        type: 'template',
        src: 'http://placehold.it/1280x800',
      },
      layers: [{
        version: 1,
        name: 'New Layer',
        type: 'text',
        extra: {
          meta: {
            value: 'Rob',
          },
        },
        position: {
          x: 0,
          y: 100,
        },
        size: {
          w: 400,
          h: 70,
        },
      }],
    };
  }

  setLayerPosition(layer, d, index) {
    this.currentScreen.layers = this.currentScreen.layers.map((l, i) => {
      if (index === i) {
        l.position.x = d.x;
        l.position.y = d.y;
      }

      return l;
    });
  }

  setLayerSize(layer, d, index) {
    this.currentScreen.layers = this.currentScreen.layers.map((l, i) => {
      if (index === i) {
        l.size.w = d.width;
        l.size.h = d.height;
      }

      return l;
    });
  }

  updateCurrentScreen(path, value) {
    _.set(this.currentScreen, path, value);
  }

  updateLayer(index, path, value) {
    this.currentScreen.layers = this.currentScreen.layers.map((l, i) => {
      if (index === i) {
        _.set(l, path, value);
      }

      return l;
    });
  }

  addLayer(layer) {
    this.currentScreen.layers.push(layer);
  }

  deleteLayer(index) {
    this.currentScreen.layers = this.currentScreen.layers.filter((l, i) => i !== index);
  }

  get getCurrentScreen() {
    return this.currentScreen;
  }

  get getJumpLocations() {
    return [{
      id: 1,
      name: 'Screen 1',
    }];
  }
}

decorate(BuilderStore, {
  currentScreen: observable,
  getCurrentScreen: computed,
});

export default new BuilderStore();
