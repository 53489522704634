// @flow
import { decorate, observable, action } from 'mobx';
import _ from 'lodash';
import BrightApi from '../../api';
import type { Course, Lesson } from 'bright';

class CourseStore {
  courses: Array<Course> = [];
  lessons: Array<Lesson> = [];

  creatingCourse: Boolean;
  creatingLesson: Boolean;

  courseDataLoaded: Boolean = false;

  async getCourses() {
    if (this.courses.length > 0) {
      return this.courses;
    }

    const { data } = await BrightApi.getCourses();
    this.courses = data;
    this.lessons = _.flatten(data.map(d => d.lessons));
    this.courseDataLoaded = true;
  }

  getLessons() {
    return this.lessons;
  }

  async createCourse(course) {
    this.creatingCourse = true;
    try {
      const created = await BrightApi.createCourse(course);
      this.courses = [];
      await this.getCourses();
      this.creatingCourse = false;

      return created.data;
    } catch (e) {
      this.creatingCourse = false;
    }
  }

  async updateCourse(course) {
    delete course.date_created;
    delete course.date_updated;

    this.creatingCourse = true;
    try {
      const created = await BrightApi.updateCourse(course);
      this.courses = [];
      await this.getCourses();
      this.creatingCourse = false;

      return created.data;
    } catch (e) {
      this.creatingCourse = false;
    }
  }
}

decorate(CourseStore, {
  courses: observable,
  getCourses: action,
  getLessons: action,
  createCourse: action,
  createLesson: action,
  updateCourse: action,
  updateLesson: action
});

export default new CourseStore();
