// @flow
import { decorate, action } from 'mobx';

import Api from '../../api';

class ContentAreaStore {
  cloneContentArea = async ({
    contentAreaIdToBeCloned,
    simulationId,
    createNewSimulation,
    simulationName,
  }) => {
    try {
      const { data } = await Api.cloneContentArea({
        contentAreaIdToBeCloned,
        simulationId,
        createNewSimulation,
        simulationName,
      });

      return data;
    } catch (err) {
      return null;
    }
  }
}

decorate(ContentAreaStore, {
  cloneContentArea: action,
});

export default new ContentAreaStore();
