// @flow
import { decorate, observable, action } from 'mobx';
import BrightApi from '../../api';
import resourceStore from '../resource/ResourceStore';

class ContentGroupStore {
  contentGroups = [];
  creatingContentGroup = false;
  selectedContentGroup = null;

  async getContentGroups(force = false) {
    if (this.contentGroups.length > 0 && !force) {
      return this.contentGroups;
    }

    const { data } = await BrightApi.getContentGroups();
    this.contentGroups = data.map(d => {
      return d;
    });

    return this.contentGroups;
  }

  async deleteContentGroup(contentGroupId) {
    const created = await BrightApi.deleteContentGroup(contentGroupId);
    this.contentGroups = [];
    await this.getContentGroups();

    return created.data;
  }

  async getContentGroup(contentGroupId) {
    if (this.selectedContentGroup != null && contentGroupId === this.selectedContentGroup.id) {
      return this.selectedContentGroup;
    }

    const { data } = await BrightApi.getContentGroup(contentGroupId);

    data.resources = data.courseOrderMeta.map(s => resourceStore.resources.find(sv => sv.id === s.id));
    this.selectedContentGroup = data;
  }

  setSelectedContentGroup(selectedContentGroup) {
    this.selectedContentGroup = selectedContentGroup;
  }

  async createContentGroup(lp) {
    this.creatingContentGroup = true;
    try {
      const created = await BrightApi.createContentGroup(lp);
      this.contentGroups = [];
      await this.getContentGroups();
      this.creatingContentGroup = false;

      return created.data;
    } catch (e) {
      this.creatingContentGroup = false;
    }
  }

  async updateContentGroup(lp) {
    this.creatingContentGroup = true;
    try {
      const created = await BrightApi.updateContentGroup(lp);
      this.contentGroups = [];
      await this.getContentGroups();
      this.creatingContentGroup = false;

      return created.data;
    } catch (e) {
      this.creatingContentGroup = false;
    }
  }
}

decorate(ContentGroupStore, {
  contentGroups: observable,
  selectedContentGroup: observable,
  getContentGroups: action,
  createContentGroup: action,
  updateContentGroup: action,
  setSelectedContentGroup: action
});

export default new ContentGroupStore();
